import 'core-js/stable';
import 'regenerator-runtime/runtime';

import textChange from './modules/textChange';
import sideNav from './modules/sideNav';
import giraSlider from './modules/giraSlider';
import tabSlider from './modules/tabSlider';
import header from './modules/header';
import accordion from './modules/accordion';
import topLink from './modules/topLink';
import scrollAnimations from './modules/scrollAnimations';
import customSelect from './modules/customSelect';
import gallery from './modules/gallery';
import video from './modules/video';
import initFormModule from './modules/form';
import modal from './modules/modal';
import defaultSlider from './modules/defaultSlider';
import reviewSlider from './modules/reviewSlider';
import newsRelatedSlider from './modules/newsRelatedSlider';
import main from './modules/main';

window.addEventListener('load', textChange);
window.addEventListener('load', sideNav);
window.addEventListener('load', giraSlider);
window.addEventListener('load', tabSlider);
window.addEventListener('load', header);
window.addEventListener('load', accordion);
window.addEventListener('load', topLink);
window.addEventListener('load', scrollAnimations);
window.addEventListener('load', customSelect);
window.addEventListener('load', gallery);
window.addEventListener('load', video);
window.addEventListener('load', initFormModule);
window.addEventListener('load', modal);
window.addEventListener('load', defaultSlider);
window.addEventListener('load', reviewSlider);
window.addEventListener('load', newsRelatedSlider);
window.addEventListener('load', main);
