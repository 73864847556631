class Form {
    /* private properties */
    $searchNode;

    constructor() {
        this.$searchNode = jQuery('.search-node');

        this.registerEvents();
    }

    registerEvents() {
        this.$searchNode?.find('.close').on('click', () => this.endSearch);
        jQuery('.content-form input').on('keyup', (event) => Form.deleteButton(event));
        jQuery('.search-button input').on('click', (event) => this.startSearch(event));
    }

    startSearch(event) {
        event.preventDefault();
        this.$searchNode?.fadeIn(350);
        jQuery('.search-item input', this.$searchNode).focus();
    }

    endSearch() {
        this.$searchNode?.fadeOut(350);
    }

    /* private methods */
    static deleteButton(event) {
        const input = jQuery(event.currentTarget);
        const item = jQuery(input).parent();

        if (item.hasClass('error')) {
            item.removeClass('error').find('span').remove();
        }

        if (input.val) {
            item.toggleClass('is-full');

            if (item.find('.delete').length === 0) {
                item.append('<div class="delete"></div>');

                item.find('.delete').on('click', () => {
                    input.val('');
                    item.find('.delete').remove();
                });
            }
        } else {
            item.find('.delete').remove();
        }
    }
}

export default function initFormModule() {
    return new Form();
}
